@import 'tcp';

.chart-states {
  fill: none;
  stroke: white;
  stroke-linejoin: round;
  stroke-width: 1px;
}

.chart-states path {
  stroke-width: 1px;
}

.chart-us {
  cursor: pointer;

  .us-clickable-area {
    fill: none;
    cursor: pointer;
  }
}

.popover {
  z-index: 999;
}

.chart-popover {
  pointer-events: none;
  transition: opacity 0.1s;
}
.chart-popover .popover-title {
  white-space: nowrap;
  padding: 5px 6px 0 4px;
  background-color: transparent;
  border: 0;
  font-weight: 300;
  font-size: 15pt;
}
.chart-popover .popover-content {
  width: 250px;
  padding: 2px 4px 2px 4px;
}

.state-label-container,
.us-label-bg {
  fill: none;
  stroke: #908c88;
  rx: 3px;
  ry: 3px;
}

  
.is-hovered {
  cursor: pointer;
  stroke: @state-selected-color !important;
  transition: stroke-width 0.5s;
  &.state {
    stroke-width: 3px;
    filter: url(#orange-glow);
  }
  rect {
    stroke-width: 3px;  
  }
}

.us-description {
  font-size: 1.6em;
}

.chart-us-group.is-hovered {
  stroke: none !important;

  .us-label-bg {
    stroke-width: 3px;
    stroke: @state-selected-color !important;
    filter: url(#orange-glow);
  }
  .us-description {
  }
}

.state-label.is-hovered text,
.state-label.is-selected text,
.chart-us-group.is-hovered .us-label-text,
.chart-us-group.is-selected .us-label-text {
  stroke: none;
}

.state {
  transition: stroke-width 0.5s;
}

.state.is-disabled {
  fill: #ddd;
  cursor: default;
}

.state-label.is-selected .state-label-container,
.state.is-selected,
.chart-us-group.is-selected .us-label-bg {
  stroke: @state-selected-color;
  transition: stroke-width 0.5s;
  stroke-width: 3px;
  filter: url(#orange-glow);
}

.state-label-text,
.us-label-text {
  font-size: 14px;
  font-family: Verdana, sans-serif;
  cursor: default;
  font-weight: 300;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;

  &.mod-callout {
    pointer-events: initial;
  }
}
.state-label-text:hover,
.us-label-text:hover {
  cursor: pointer;
}
.state-label-text.is-disabled {
  font-weight: inherit;
}
.state-label-text.is-disabled:hover {
  cursor: default;
}

.state-label-callout {
  stroke: #908c88;
  stroke-width: 1px;
}

.choropleth-container {
  .chart-title {
    font-size: 2.5em;  
  }

  .chart-legend-label {
    font-size: 1.4em;
  }

  .legend-title {
    font-size: 1.6em;
  }
}

.choropleth-source {
  font-size: 1.5em;
  font-weight: normal;
}
