.policy-header {
  margin-bottom: 1em;
}

.policy-subtitle {
  margin-top: 0.5em;
}

.policy-controls {
  .inline-form-control {
    display: inline-block;
    width: auto;
    height: 2em;
    font-size: 0.8em;
    padding: 0 0 0 0.2em;
  }
  .range-dropdown {
    display: inline-block;

    .dropdown-toggle {
      height: 2em;
      font-size: 0.8em;
      padding: 0 0.4em 0 0.5em;

      .caret {
        margin-left: 0.2em;
      }
    }
  }

}
.policy-additional-controls {
  .control-label {
    font-weight: normal;
  }
}

@media (min-width: 768px) {
  .policy-subtitle {
    margin-top: 0;
  }
  .policy-summary {
    width: 45%;
  }
  .policy-choropleth-container {
    // TODO adjust sizing / margins in chart
    margin-left: -50px;
    margin-right: -50px;
  }
  .policy-details {
    width: 55%;
  }
}