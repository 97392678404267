#slideshow-home, #slideshow-ghw {
  position: relative;
  display: block;
  img {
      position: absolute;
      z-index: 1;
      display: block;
      max-width: 100%;
      max-height: 100%;
      height: auto;
      .active {
      z-index:3
      }
  }
}

#slideshow-ghw {
  img {
    cursor: pointer;
  }
}

@media (min-width: 800px) {
  #slideshow-home, #slideshow-ghw {
      height: 360px
  }
}

@media (max-width: 800px) {
  #slideshow-home, #slideshow-ghw {
      height: 250px
  }
}

.slideshow-blinker {
  background-color: lightgray;
  -webkit-animation: fadeinout 250ms linear forwards;
  animation: fadeinout 250ms linear forwards;
}

@-webkit-keyframes fadeinout {
  0%,100% { background-color: white; }
  50% { background-color: lightgray; }
}

@keyframes fadeinout {
  0%,100% { background-color: white; }
  50% { background-color: lightgray; }
}