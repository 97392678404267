.lock-button.btn {
  padding: 0;
  font-size: 90%;

  &:hover {
    background-color: transparent;
  }

  .tooltip-component {
    width: 100%;
    height: 1em;
  }
  .lock-button-label {
    float: left;
  }
  .icon-lock, .icon-unlock {
    margin-right: -7px;
    margin-left: 2px;
    margin-bottom: 9px;
    fill: #BBB;
    &:hover {
      fill: #888;
    }
  }
}

.range-dropdown {
  > .dropdown-toggle {
    color: #555;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;

    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus {
      background-color: #eee;
      color: #555;
      opacity: 1;
      border-color: #ccc;
      box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    }

    &.is-flashing {
      animation: flash 3s;
    }
  }

  > .dropdown-toggle:hover,
  > .dropdown-toggle:active,
  &.open > .dropdown-toggle,
  &.open > .dropdown-toggle:focus,
  &.open > .dropdown-toggle:hover {
    background-color: #fff;
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
  }
}

.range-dropdown-menu {
  padding: 0.5em 0.75em 0 1.5em;
  width: 300px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .slider-component {
    display: inline-block;
    width: ~"calc(100% - 85px)";
    margin-top: 5px;
  }
  .btn {
    display: inline-block;
    width: 65px;
    margin-left: 15px;
    margin-top: -35px;
  }
}

.select-component {
  &.is-flashing {
    animation: flash 3s;
  }
}

@keyframes flash {
  0% {
    background-color: inherit;
  }
  10%, 60% {
    background-color: #fefca2;
  }
  100% {
    background-color: inherit;
  }
}