@import 'tcp';

.slider-component {
  .slider.slider-horizontal {
    width: 100%;
  }
  .slider-track {
    cursor: auto;
  }
  .slider-tick {
    cursor: pointer;
  }
  .slider-handle {
    cursor: pointer;
    background-color: white !important;
    background-image: none !important;
    border: 2px solid @initial-color;
  }

  .slider-disabled .slider-tick {
    background-image: linear-gradient(to bottom,#f9f9f9 0,#f5f5f5 100%);
  }
  .slider-disabled .slider-tick-label {
    color: #DDD;
  }
  .slider-disabled .slider-handle{
    background-color: transparent;
  }
  .slider-disabled .slider-selection {
    background-image: none;
    background-color: transparent;
  }

  &.mod-labels-above {
    .slider-tick-label-container {
      margin-top: 0;
    }
    .slider-handle,
    .slider-tick-container {
      margin-top: 24px;
    }
    .slider-track {
      margin-top: 19px;
    }
  }

  .slider-rangeHighlight {
    background: #a94442;
    z-index: 1001;
  }

  .tooltip {
    z-index: 999;
  }
  .tooltip.bottom .tooltip-arrow {
    border-bottom-color: @initial-color;
  }

  .tooltip-inner {
    background-color: @initial-color;
  }
  .tooltip-arrow {
    border-top-color: @initial-color !important;
  }

}

.min-slider-handle {
  background-color: @initial-color !important;
  background-image: none !important;
}

.policy {
  .tooltip-arrow {
    border-top-color: @policy-color !important; 
  }
  .tooltip-inner {
    background-color: @policy-color; 
  }

  .slider-handle {
    background-color: @policy-color !important;
    border-color: @policy-color;
  }
}

.slider-handle.max-slider-handle.round {
    background-color: @policy-color !important;
    background-image: none !important;
    border: 2px solid @policy-color;
}

.policy-max {
  .tooltip-max {
    .tooltip-arrow {
      border-top-color: @policy-color !important; 
    }
    .tooltip-inner {
      background-color: @policy-color; 
    }
  }
  .min-slider-handle {
    background-color: white !important;
    background-image: none !important;
    border: 2px solid @initial-color;
  }

  .slider-tick.in-selection {
    background-image: -webkit-linear-gradient(top, #f9f9f9 0%, #f5f5f5 100%);
    background-image: -o-linear-gradient(top, #f9f9f9 0%, #f5f5f5 100%);
    background-image: linear-gradient(to bottom, #f9f9f9 0%, #f5f5f5 100%);
  }
}

.locked-slider-control {
  user-select: none;

  .tooltip-inner {
    .is-locked { display: inline-block; }
    .is-unlocked { display: none; }
  }

  .tooltip-min:hover,
  .tooltip-main:hover {
    .tooltip-inner {
      cursor: pointer;
      background-color: #999;
    }
    .tooltip-arrow {
      border-top-color: #999 !important;
    }
  }

  .min-slider-handle {
    cursor: not-allowed;
  }
  .max-slider-handle {
    cursor: pointer;
  }

  &.is-unlocked {
    .min-slider-handle {
      cursor: pointer;
    }
    .tooltip-inner {
      .is-locked { display: none; }
      .is-unlocked { display: inline-block; }
    }
  }
}
