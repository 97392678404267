.checkbox-component {
  .checkbox {
    margin-top: 2px;
    margin-bottom: 2px;
  }
  .checkbox-label {
    display: inline-block;
    margin-left: 20px;
    margin-top: 6px;
  }
}