@import 'tcp';

.explorer-component {
  position: relative;
}

.explorer-nav {
  position: relative;
  list-style: none;
  font-size: 1.1em;
  margin: 1em 0;
}

.explorer-nav-tab {
  position: relative;
}

@media (min-width: 600px) {
  .explorer-nav-tab {
    float: left;
    width: 20%;
  }
  .t21 .explorer-nav-tab {
    width: 16.6%;
  }
}

.explorer-nav-link {
  display: inline-block;
  width: 100%;

  text-decoration: none;
  border: none;
  border-bottom: solid 3px transparent;
  padding: 0.5em 0.2em;
  text-align: center;
  background-color: transparent;
  color: @color-highlight-blue;

  &:hover,
  &:focus {
    background-color: #ebebeb;
    text-decoration: none;
    outline: 0;
  }

  &.active {
    border-color: @color-highlight-blue;

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
      background-color: #fff;
      cursor: default;
    }
  }

  &.mod-overview {
    line-height: 3em;
  }
  &.is-NA {
    color: #aaa;

    &.active {
      border-color: #aaa;
    }
  }
}

@media (max-width: 599px) {
  .explorer-nav-link {
    border-bottom: none;
    border-left: solid 3px transparent;
    text-align: left;
    padding-left: 1em;
  }
}

.explorer-nav-value {
  display: block;
  font-size: 1.5em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &.mod-range {
    font-size: 1em;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .explorer-nav-value {
    font-size: 1.1em;
  }
}

.explorer-nav-title {
  display: block;
  font-size: 0.8em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.explorer-no-data {
  display: none;
  position: absolute;
  top: -1.4em;
  right: 0;
  width: 40%;
  text-align: center;

  .badge {
    background-color: #ccc;
    color: black;
    padding: 0.4em 0.8em;
    font-weight: normal;
  }

  &.is-NA {
    display: block;
  }
}

//
// Overview
//

.explorer-overview-section {
  border: solid 2px transparent;
  padding: 0;

  &:hover {
    border-color: #ddd;
    background-color: #f5f5f5;
    transition: background-color 0.2s, border-color 0.2s;
  }
}

.explorer-overview {
  padding-right: 25px;
}