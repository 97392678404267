.table-labels {
  position: relative;
  pointer-events: none;
  z-index: 900;
  transition: opacity 0.2s;
}
.table-labels-arrow {
  position: absolute;
  top: -8px;
  left: 0;
  background-color: transparent;
}
.table-labels-arrow path {
  fill: white;
  stroke: #ccc;
}
.table-labels.is-inverted .table-labels-arrow {
  left: auto;
  right: 0;
}
.table-labels-list,
.table-labels-table {
  position: absolute;
  -webkit-transform: translate(11px, -50%);
  -ms-transform: translate(11px, -50%);
  transform: translate(11px, -50%);
  background-color: white;
  box-shadow: 0px 0px 5px #aaa;
}
.table-labels-list {
  margin: 0;
  padding: 2px 0 5px 0;
  list-style: none;
}
.table-labels-table {
  margin-top: 0 !important;
}
.table-labels-item {
  margin: 0px 8px;
  padding: 0;
  font-size: 12px !important;
  white-space: nowrap;
}
.is-fullscreen .table-labels-item {
  font-size: 14px !important;
}
@media screen and (max-width: 1000px) {
  .table-labels-item,
  .is-fullscreen .table-labels-item {
    font-size: 10px !important;
  }
}
.table-labels.is-inverted .table-labels-list,
.table-labels.is-inverted .table-labels-table {
  -webkit-transform: translate(-103%, -50%);
  -ms-transform: translate(-103%, -50%);
  transform: translate(-103%, -50%);
  -webkit-transform: translate(calc(-100% - 11px), -50%);
  -ms-transform: translate(calc(-100% - 11px), -50%);
  transform: translate(calc(-100% - 11px), -50%);
}
.table-labels-swatch {
  display: inline-block;
  position: relative;
  top: 3px;
  margin-right: 4px;
}
.table-labels-item {
  padding: 0 0 0 6px;
  line-height: 22px;
  min-width: 45px;
}
.table-labels-item:last-child {
  padding-right: 6px;
}
.table-labels-header {
  font-weight: bold;
}
.table-labels-table .table-labels-header {
  padding: 5px 0 0 6px;
}
.table-labels-row-header {
  text-align: right;
  padding-right: 2px;
  font-weight: 300;
}
.table-labels-col-header {
  font-weight: 300;
}
