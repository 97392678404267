@import 'tcp';

@top-border-width: 3px;
@header-height: 36px;

.tcp-header {
  min-height: @header-height;
  max-height: @header-height;
  background-color: @nav-blue;

  .navbar-toggle {
    margin: 2px 4px 0 0;
  }
  .icon-bar {
    background-color: white;
  }
  .navbar-collapse {
    border-top: none;
  }
  .navbar-nav {
    margin-top: 0;
  }
}

.tcp-header-home {
  display: inline-block;
  height: auto;
  padding: 10px 20px 5px 15px;
  color: #fff;

  &:hover,
  &:focus {
    color: #e6e6e6;
  }
}
.nav {
  li.tcp-header-link {
    a {
      display: inline-block;
      height: @header-height;
      padding: 0 15px;
      line-height: 30px !important;
      color: #fff;
      background-color: @nav-blue;
      border-radius: 0;
      border-top: solid 6px lighten(@nav-blue, 10%);
      text-shadow: 0px 1px 2px #000;

      &:hover {
        background-color: darken(#5c8eb8, 20%);
        border-top-color: lighten(#5c8eb8, 10%);
      }
    }

    &.mod-airlaws {
      a, a:focus {
        background-color: @airlaws-color;
        border-top-color: lighten(@airlaws-color, 20%);
      }
      a:hover  {
        background-color: darken(@airlaws-color, 8%);
        border-top-color: lighten(@airlaws-color, 5%);
      }
    }

    &.mod-taxes {
      a, a:focus {
        background-color: @taxes-color;
        border-top-color: lighten(@taxes-color, 20%);
      }
      a:hover {
        background-color: darken(@taxes-color, 3%);
        border-top-color: lighten(@taxes-color, 15%);

      }
    }

    &.mod-expenditure {
      a, a:focus {
        background-color: @expenditure-color;
        border-top-color: lighten(@expenditure-color, 20%);
      }
      a:hover {
        background-color: darken(@expenditure-color, 3%);
        border-top-color: lighten(@expenditure-color, 15%);
      }
    }

    &.mod-t21 {
      a, a:focus {
        background-color: @t21-color;
        border-top-color: lighten(@t21-color, 20%);
      }
      a:hover {
        background-color: darken(@t21-color, 3%);
        border-top-color: lighten(@t21-color, 15%);
      }
    }

    &.mod-health {
      a, a:focus {
        background-color: @health-color;
        border-top-color: lighten(@health-color, 20%);
      }
      a:hover {
        background-color: darken(@health-color, 3%);
        border-top-color: lighten(@health-color, 15%);
      }
    }

    &.active a:before {
     color: #fff;
    }
  }
}

@media (max-width: 768px) {
  .nav li.tcp-header-link a {
    width: 100%;
  }
}

// Adjust for iPads
@media (min-width: 992px) and (max-width: 1200px) {
  .nav li.tcp-header-link a {
    font-size: 90%;
    padding: 0 10px;
  }
  .navbar .navbar-nav > li {
    font-size: 90%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .nav li.tcp-header-link a {
    font-size: 78%;
    padding: 0 10px;
  }
  .navbar .navbar-nav > li {
    font-size: 78%;
  }
}

@media (min-width: 768px) {
  .navbar .navbar-nav > li {
    &.active {
      position: relative;

      &.mod-airlaws:after { border-top-color: @airlaws-color; }
      &.mod-t21:after { border-top-color: @t21-color; }
      &.mod-expenditure:after { border-top-color: @expenditure-color; }
      &.mod-taxes:after { border-top-color: @taxes-color; }
      &.mod-health:after { border-top-color: @health-color; }
    }
    &.active:after {
      border-top: 10px solid @nav-blue;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      content: "";
      height: 0;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      top: @header-height;
      width: 0;
    }

    &.active:hover::after {
      border-top-color: darken(#5c8eb8, 20%);
    }
    &.active.mod-taxes:hover::after { border-top-color: darken(@taxes-color, 3%); }
    &.active.mod-airlaws:hover::after { border-top-color: darken(@airlaws-color, 8%); }
    &.active.mod-expenditure:hover::after { border-top-color: darken(@expenditure-color, 3%); }
    &.active.mod-t21:hover::after { border-top-color: darken(@t21-color, 3%); }
    &.active.mod-health:hover::after { border-top-color: darken(@health-color, 3%); }
  }

  .navbar-text {
    float:none;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

// For small screen sizes, force the footer to go below the fold
@media (max-height: 799px) {
  .navbar-fixed-bottom {
    position: initial;
  }
}
