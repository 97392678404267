//
// Panels
//
.homepage-panel {
  margin-top: 10px;
  text-shadow: 0px 1px 2px #000;

  .image-hover {
    padding: 10px;
    margin: 0;
    border-radius: 0px;
    display: inline-block;
    direction: ltr;
    padding: 0;
    width: 100%;

    .container {
      background-repeat: repeat;
      background-position: 0 0;
      position: relative;
      display: block;
      height: 16em;
      // margin: 0 11px;
      width: 100%;
      padding: 0;
    }
    .container:hover:before {
      content: " ";
      display: block;
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: rgba(3,3,3,0.2);
    }

    .tool-title {
      font-size: 1.7em;
      padding: 12px 16px 12px 12px;
      display: block;
      color: white;
      margin-top: 0;
      margin-bottom: 5px;
      font-weight: 400;
    }

    a, a:hover {
      text-decoration: none;
    }

    .tool-link {
      display: block;
      width: 100%;
      padding: 12px 16px 12px 12px;
      margin: 0;
      bottom: 0;
      position: absolute;
      color: #fff;
      background-color: #403e3f;

    }

    .airlaws {
      background-image: url("../img/aqua-circles.jpg");
    }
    .taxes {
      background-image: url("../img/blue-dots.jpg");
    }
    .expenditure {
      background-image: url("../img/green-plaid.jpg");
    }
    .tobacco-21 {
      background-image: url("../img/yellow-hexagons.jpg");
    }
    .health {
      background-image: url("../img/orange-triangles.jpg");
    }

    svg {
      fill: white;
    }

    @media screen and (max-width: 768px){
      .tool-title { font-size: 1.4em;}
    }

    @media screen and (min-width:768px) and (max-width: 992px){
      .tool-title { font-size: 1em;}
    }

    @media screen and (max-width: 992px) {
      .container { height: 12em; }
      .tool-link { font-size: 1.1em; }
    }
  }
}

.tcp-update-alert {
  //h2 {margin-top: 0px;}
  font-size: 90%
}
