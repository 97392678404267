.tour-tour {
  font-weight: 400;
  font-family: Roboto Flex, sans-serif;

  p {
      font-size: 1.3em;
      line-height: 1.6em;
  }

  strong {
    font-weight: 600;
  }

  ol {
    font-size: 1.3em;

    li {
      margin-bottom: 0.4em;
    }
  }
  .popover-title {
    font-size: 1.3em;
  }
  .popover-content {
    padding-top: 0;
  }

  .pbar_wrapper {
    padding-bottom: 9px;
    margin: -1px -14px 0;
  }

  .pbar {
    margin: 0;
    border-top: 3px solid #337ab7;
    text-align: left;
  }
}

.tour-backdrop {
  background-color: black;
  opacity: 0.6;
}