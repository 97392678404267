@import 'tcp';

svg {
  user-select: none;
}

.details-view .tcp-chart .chart-compose {
  overflow: visible;
}

.tcp-chart {
  font-family: Roboto Flex, sans-serif;

  .chart-compose {
    background-color: transparent;
    border: none;
  }
  .chart-title {
    font-size: 1.3em;
    font-weight: 300;
  }
  .chart-subtitle {
    font-size: 0.7em;
    font-weight: 300;
  }
  .chart-axis-title {
    font-weight: 300;
  }

  .no-data {
    fill: #666;
  }
  .no-data,
  .no-data svg {
    background-color: #eee;
  }

  .chart-axis {
    path {
      stroke: #eee;
      stroke-width: 1px;
    }
    .tick {
      fill: #555;
      stroke: none;
    }
    .tick line {
      stroke: #aaa;
    }
    text {
      font-size: 12px;
      font-family: Roboto Flex, sans-serif;
    }
  }
}


.chart-caption text{
  font-family: Roboto;
}
.chart-hero-caption {
  display: none;
}
.chart-hero-caption text {
  font-family: Roboto;
  font-size: 80pt;
  fill: #EEE;
}

.chart-gridline {
  stroke-dasharray: 1,4;
  stroke: #999;
  stroke-width: 0px;
}

.arrow-label .chart-label-text {
  font-size: 10px;
}
.arrow-label .chart-label-bg {
  opacity: 1;
  stroke-width: 2px;
}

.type-baseline .chart-line {
  stroke-dasharray: 0 2 2;
}
.reduction .chart-line {
  stroke-dasharray: none;
}

/*
  cohort coloring
*/
.cohort-1970 .chart-label-bg,
.cohort-1970 .chart-line,
.chart-end-value-points .cohort-1970 path {
  stroke: @cohort-1970-color;
}
.cohort-1980 .chart-label-bg,
.cohort-1980 .chart-line,
.chart-end-value-points .cohort-1980 path {
  stroke: @cohort-1980-color;
}
.cohort-1990 .chart-label-bg,
.cohort-1990 .chart-line,
.chart-end-value-points .cohort-1990 path {
  stroke: @cohort-1990-color;
}
.cohort-2000 .chart-label-bg,
.cohort-2000 .chart-line,
.chart-end-value-points .cohort-2000 path {
  stroke: @cohort-2000-color;
}
.cohort-2010 .chart-label-bg,
.cohort-2010 .chart-line,
.chart-end-value-points .cohort-2010 path {
  stroke: @cohort-2010-color;
}
.cohort-2020 .chart-label-bg,
.cohort-2020 .chart-line,
.chart-end-value-points .cohort-2020 path {
  stroke: @cohort-2020-color;
}
.cohort-single .chart-label-bg,
.cohort-single .chart-line,
.chart-end-value-points .cohort-single path {
  stroke: @color-gender-both;
}

.chart-area .cohort-1970,
.cohort-1970 .chart-label-text {
  fill: @cohort-1970-color;
}
.chart-area .cohort-1980,
.cohort-1980 .chart-label-text {
  fill: @cohort-1980-color;
}
.chart-area .cohort-1990,
.cohort-1990 .chart-label-text {
  fill: @cohort-1990-color;
}
.chart-area .cohort-2000,
.cohort-2000 .chart-label-text {
  fill: @cohort-2000-color;
}
.chart-area .cohort-2010,
.cohort-2010 .chart-label-text {
  fill: @cohort-2010-color;
}
.chart-area .cohort-2020,
.cohort-2020 .chart-label-text {
  fill: @cohort-2020-color;
}

.cohort-1970 .chart-symbol,
.chart-end-value-points .cohort-1970>path {
  fill: @cohort-1970-color;
  stroke: @cohort-1970-color;
}
.cohort-1980 .chart-symbol,
.chart-end-value-points .cohort-1980>path {
  fill: @cohort-1980-color;
  stroke: @cohort-1980-color;
}
.cohort-1990 .chart-symbol,
.chart-end-value-points .cohort-1990>path {
  fill: @cohort-1990-color;
  stroke: @cohort-1990-color;
}
.cohort-2000 .chart-symbol,
.chart-end-value-points .cohort-2000>path {
  fill: @cohort-2000-color;
  stroke: @cohort-2000-color;
}

.cohort-2010 .chart-symbol,
.chart-end-value-points .cohort-2010>path {
  fill: @cohort-2010-color;
  stroke: @cohort-2010-color;
}
.cohort-2020 .chart-symbol,
.chart-end-value-points .cohort-2020>path {
  fill: @cohort-2020-color;
  stroke: @cohort-2020-color;
}

/*
  gender coloring (takes precendence over cohort)
*/
.gender-males .chart-symbol,
.chart-end-value-points .gender-males path,
.legend-item .gender-males path {
  stroke: @color-gender-males;
  fill: @color-gender-males;
}
.gender-males .chart-label-bg,
.gender-males .chart-line,
.arrow-label.gender-males .chart-label-bg {
  stroke: @color-gender-males;
}
.gender-females .chart-symbol,
.chart-end-value-points .gender-females path,
.legend-item .gender-females path {
  stroke: @color-gender-females;
  fill: @color-gender-females;
}
.gender-females .chart-label-bg,
.gender-females .chart-line,
.arrow-label.gender-females .chart-label-bg {
  stroke: @color-gender-females;
}
.gender-both .chart-symbol,
.chart-end-value-points .gender-both path,
.legend-item .gender-both path {
  stroke: @color-gender-both;
  fill: @color-gender-both;
}
.gender-both .chart-label-bg,
.gender-both .chart-line,
.arrow-label.gender-both .chart-label-bg {
  stroke: @color-gender-both;
}

.chart-area .gender-males,
.gender-males .chart-label-text {
  fill: @color-gender-males;
}
.chart-area .gender-females,
.gender-females .chart-label-text {
  fill: @color-gender-females;
}
.chart-area .gender-both,
.gender-both .chart-label-text {
  fill: @color-gender-both;
}

/*
  overview coloring
*/
.tcp-chart.mod-small .gender-both .chart-line {
  stroke: black;
}

/*
  end-year-lines
*/
.chart-lines {

  .chart-line {
    stroke-width: 1px;
    shape-rendering: geometricPrecision;
  }

  .gender-both .chart-line {
    stroke-width: 2px;
  }

  .chart-series .chart-line {
    transition: opacity 0.5s;
  }
  .chart-series.is-faded .chart-line {
    opacity: 0.3;
  }
  .chart-series.is-filtered .chart-line {
    opacity: 0;
  }
  .chart-series.is-filtered.is-faded .chart-line {
    opacity: 0.3;
  }

}

/*
  area
*/
.chart-area {
  opacity: 0.3;
  stroke: none;
  pointer-events: none;
}
.chart-series.is-faded .chart-area {
  opacity: 0.3;
}
.chart-series.is-filtered .chart-area {
  opacity: 0;
}

/*
  legend
*/
.chart-legend-label {
  font-size: 9pt;
}

/*
  overlay-line
*/
@overlay-color: #666;
.overlay-area {
  fill: white;
}
.overlay-path {
  fill: none;
  stroke-width: 0.8px;
  stroke: #ddd;
}
.overlay-arrow {
  fill: @overlay-color;
  stroke: none;
}
.overlay-label {
  stroke: none;
  fill: @overlay-color;
  font-size: 10px;
  font-weight: 400;
}
.chart-overlay.is-hidden {
  visibility: hidden;
}

/*
  overlay-area
*/
.chart-overlay-area {
  fill: none;
  pointer-events: none;
}

/*
  table-legend
*/
.table-legend .legend-column {
  cursor: pointer;
  transition: opacity 0.5s;

  &.is-filtered > * {
    opacity: 0.2;
  }
  &:hover .legend-column-bg,
  &.is-filtered:hover .legend-column-bg {
    opacity: 1;
    stroke-width: 2px;
    stroke: lightblue;
  }

  .tcp-chart.mod-small &:hover .legend-column-bg {
    stroke: none;
  }

  &.mod-column-headers {
    cursor: default;
  }
  &.mod-column-headers .legend-column-bg {
    stroke: none;
  }
}
.legend-header {
  font-size: 0.7em;
}
.legend-column-bg {
  fill: transparent;
}

/*
  overlay-text
*/
.overlay-text text {
  font-size: 34px;
  fill: #999;
}

.na-overlay {
  a {
    text-decoration: none;
  }

  text {
    font-size: 120%;
    font-weight: 300;
    fill: @color-highlight-blue;
  }

  .reset-na-parameters {
    text-decoration: underline;
    cursor: pointer;
  }
}

.chart-end-value-points {
  path {
    opacity: 1;
    transition: opacity 0.5s;

    &.is-faded {
      opacity: 0.3;
    }
    &.is-hidden {
      opacity: 0;
    }
    &.is-hidden.is-faded {
      opacity: 0.3;
    }
  }
  .end-value-line {
    transition: opacity 0.5s;
    stroke: #444;
    stroke-width: 1px;
    stroke-dasharray: 2,4;

    &.is-transient {
      stroke: #888;
      stroke-dasharray: 1,5;
    }
  }
  .chart-series.is-hidden path {
    opacity: 0;
  }
  .end-value-line.is-hidden {
    opacity: 0;
  }
}

// TODO: review placement of the following 2 rules, and maybe better done in js
.mod-lines {
  padding-right: 10px;
  .chart-label-bg {
    height: 15px;
    transform: translate(10px,2px);
  }
}
.mod-small .chart-label-bg {
  stroke-width: 0px;
}

.end-value-labels {
  pointer-events: none;

  .chart-label-bg {
    fill: #fff;
    opacity: 0.9;
    stroke-width: 0.4px;
  }

  .chart-label {
    transition: opacity 0.5s;
    opacity: 1;

    &.is-faded {
      opacity: 0.3;
    }
    &.is-hidden {
      opacity: 0;
    }
    &.is-hidden.is-faded {
      opacity: 0.3;
    }
  }

  .chart-series {
    transition: opacity 0.5s;

    &.is-hidden {
      opacity: 0;
    }
  }
}

.end-year-labels {
  .chart-label {
    transition: opacity 0.5s;
    opacity: 1;
    stroke-width: 0px;
    &.is-faded {
      opacity: 0.3;
    }
    &.is-hidden {
      opacity: 0;
    }
    &.is-hidden.is-faded {
      opacity: 0.3;
    }
  }
}

.progress-component {
  width: 100%;
  min-width: 100%;
  white-space: nowrap;

  &.is-offset {
    .progress-bar {
      position: relative;
    }
    span {
      display: inline-block;
      position: absolute;
      right: 0;
      transform: translateX(calc(100% + 4px));
    }
  }
}