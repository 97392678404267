@import "vendor/wtf-forms";
@import "vendor/stateface";

@checkbox-color: #89cdef;
@color-gender-females: #C0504D;
@color-gender-males: #416FA6;
@color-gender-both: black;
@cohort-1970-color: #1f77b4;
@cohort-1980-color: #ff7f0e;
@cohort-1990-color: #2ca02c;
@cohort-2000-color: #d62728;
@cohort-2010-color: #9467bd;
@cohort-2020-color: #1f77b4;
@state-selected-color: orange;
@nav-blue: #44749D;
@airlaws-color: #48a4b4;
@taxes-color: #2f8fbe;
@expenditure-color: #79b52d;
@t21-color: #e1c813;
@health-color: #ee7744;
@initial-color: #444;
@policy-color: #607d8b;
@policy-color-light: lighten(@policy-color, 10%);
@policy-checkbox-color: #a1eca1;
@color-highlight-blue: #337ab7;

@import "table-labels";
@import "slider-component";
@import "info-component";
@import "checkbox-component";
@import "explorer-component";

@import "navbar";
@import "home";
@import "policy";
@import "tour";
@import "charts";
@import "controls";
@import "choropleth";
@import "overlay";
@import "details";
@import "slideshow";

body {
  padding-top: 50px;
  font-family: Roboto Flex, sans-serif;
  font-weight: 400;
  overflow-y: scroll;
}

h1 {
  font-size: 2.2em;
  font-weight: normal;
}
h2 {
  font-size: 1.6em;
  font-weight: 600;
}
h3 {
  font-size: 1.4em;
  font-weight: 400;
  margin-top: 8px;
  margin-bottom: 5px;
}

p {
  font-size: 1.2em;
  line-height: 1.8em;
}

td,
th {
  font-size: 120%;
}
.btn {
  font-family: sans-serif;
}

ol li {
  margin-top: 0.8em;
}

.inline {
  display: inline;
}

/*
  Slides
*/

/*
  Helpers
*/
.progress {
  margin-top: 1px;
}

.proportion-label {
  margin-top: 10px;
}

.no-padding {
  padding-left: 0;
  padding-right: 0;
}

.no-margin {
  margin-left: 0;
  margin-right: 0;
}

body.is-fullscreen .container {
  width: 100%;
}

#fullscreen-btn {
  display: none;
  margin-top: 10px;
}

@media (min-width: 800px) {
  #fullscreen-btn {
    display: block;
  }
}

.row-border > div:first-child {
  border-right: 1px solid #DDD;
}
.row-border > div {
  padding-top: 10px;
}

.row-border {
  border-top: 1px solid #DDD;
}
.row-border + div{
  border-top: 1px solid #DDD;
}

.output {
  border-top: 1px solid #DDD;
  height: 8px;
}

.text-nowrap {
  white-space: nowrap;
}

.chevron-list li {
  display: block;
  margin-bottom: 0.25em;

  &:before {
    content: "\e080";
    float: left;
    margin-top: 0.6em;
    margin-left: -17px;
    font-family: 'Glyphicons Halflings';
    font-size: 0.6em;
    color: #ccc;
  }
}

.modal {
  text-align: center;

  table {
    margin-top: 5px;
    font-size: 70%;
    width: 100%;
    -webkit-column-break-inside: avoid;
  }

  p {
    font-size: 1.2em;
    line-height: 1.6em;
  }
  .tab-content {
    margin-top: 0.4em;
  }
  ol {
    padding-left: 1.2em;
  }
}
@media (max-width: 768px) {
  .modal p {
    font-size: 1.1em;
    line-height: 1.6em;
  }
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

@media (min-width: 1590px) {
  .container {
    width: 75%;
  }
}

@media (min-width: 768px) {
  .modal-xl {
    width: 90%;
    max-width:1200px;
  }
  .modal:before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100%;
  }
}

.tcp-content {
  margin-bottom: 100px;
  min-height: 500px;
}
@media screen and (min-width: 768px) {
  .tcp-content {
    padding-left: 0;
    padding-right: 0;
  }
}
@media screen and (max-height: 800px) {
  .tcp-content {
    margin-bottom: 1em;
  }
}

.header-text {
  font-size: 2.5em;
  display: inline-block;
  margin-top: 0.1em;
  margin-bottom: 0;
}

.header-buttons {
  position: relative;
  top: 4px;
  display: inline-block;

  a {
    text-decoration: none;
  }
}
@media screen and (min-width: 768px) {
  .header-buttons {
    float: right;

    .btn {
      margin-left: 10px;
    }
    > .btn:first-child {
      margin-left: 0;
    }
    .share-button-component {
      display: inline-block;
    }
  }
}
@media screen and (max-width: 768px) {
  .header-buttons .btn {
    margin-left: 6px;
  }
  .header-buttons .btn:first-child {
    margin-left: 0;
  }
}
@media screen and (max-width: 991px) {
  .header-text {
    font-size: 1.7em;
  }
  .header-buttons .btn {
    font-size: 0.85em;
  }
}

.header-button[disabled] {
  cursor: default;
}

//
// Summary
//

.summary-view {
  margin-top: 5px;
  padding: 5px;

  p {
    font-size: 1.5em;
    margin-bottom: 0px;
  }

  h2 {
    margin-top: 0;
    font-size: 1.7em;
    margin-left: 0px;
  }

  & > .row {
    //border-bottom: solid 1px #ddd;
  }

  .affix-container.affix & > .row {
    border-bottom: none;
  }

}

.reset-parameters {
  margin-left: 4px;
}

//
// Details
//

var {
  font-weight: 600;
  font-style: normal;
  white-space: nowrap;
}

.defaults {
  font-size: 0.8em;
  padding: 3px;

  p {
    margin:0;
    padding: 0;
  }
  var {
    background-color: transparent;
    font-weight: 300;
  }
}

//
// Info
//

.button-component {
  display: inline-block;
}

.policy-controls-subtitle{
  font-size: 0.9em
}

@media (min-width: 768px) {
  .two-col {
    column-count: 2;
  }

  .three-col {
    column-count: 3;

    ul {
      break-inside: avoid-column;
    }
  }
}

// For tour (TODO: conflicts with other popovers, change class name and refactor)
.popover {
  max-width: 600px;
}
.share-popover {
  max-width: 300px;
}


//
// Icon
//

.icon-component {
  display: inline-block;
  height: 1em;
  width: 1em;

  svg {
    position: relative;
    top: 2px;
    width: 100%;
    height: 100%;
  }

  &.color-white {
    fill: white;
  }
}

main a {
  text-decoration: underline;
}

.share-field {
  font-size: 0.85em;
  width: 200px;
}

.nowrap {
  white-space: nowrap;
}

.nav-tabs a {
  text-decoration: none;
}

.details-modal {
  .modal-body {
    min-height: 550px;
  }
}

//
// Flatline Overlay
//

.flatline-overlay-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
}
.flatline-overlay {
  width: 45em;
  margin: 14em auto 0 auto;
  font-size: 0.9em;

  .panel-body {
    font-size: 1.1em;
  }

  .icon-lock {
    fill: #BBB;
    margin-right: -4px;
  }
}

// iPhone X specific

@media only screen and (max-width: 812px) and (orientation: landscape) {
  .container {
    width: 100%;
    max-width: unset;
    padding-left: 9px;
    padding-left: ~"max(9px, env(safe-area-inset-left))";
    padding-right: 9px;
    padding-right: ~"max(9px, env(safe-area-inset-right))";
  }
}

@media print {
  .explorer-nav-tab, .header-buttons, .chart-overlay-layer {
    display: none;
  }
  .tcp-content {
    margin-bottom: 0;
  }
  body {
    padding: 0;
  }
  .details-view {
    page-break-inside: avoid;
  }
}
