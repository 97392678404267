.progress {
  margin-bottom: 4px;
}
.progress-bar {
  color: black;
  text-align: left;
  padding-left: 4px;
}

.footer {
  font-size: 80%
}

.hint {
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px;
}

.fda-warning {
  max-width: 180px;
}