@import 'tcp';

@slider-height: 50px;

.jumbo {
  font-size: 60pt;
  font-weight: 300;
  display: block;
  line-height: normal;
  background-color: transparent;
}
.big {
  font-size: 20pt;
  font-weight: 300;
  display: block;
}

.details-row {
  position: relative;
}

@media (min-width: 768px) {
  .details-row {
    display: flex;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: -10px;
    margin-right: -10px;
  }
  .details-row-item {
    flex: 1;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.details-modal-container {
  display: flex;
}

.details-modal-column {
  width: 0;

  &.mod-main {
    flex: 2;
  }
  &.mod-aside {
    flex: 1;
    position: relative;
  }
}

@media (min-width: 1350px) {
  .details-modal-column.mod-main {
    min-height: 520px;
  }
  .summary-view p {
    line-height: 2em;
  }
}

// Adjust for iPads
@media (max-width: 1024px) {
  .details-info {
    font-size: 97%;
  }
}

.sources {
  text-align: center;
  font-size: 10pt;
}

.outputs {
  position: relative;
  padding-bottom: 5px;
  padding-top: 5px;
}

.details-button {
  margin-top: 10px;
  height: 100%;
  position: relative;
}

.details-button {
  border: solid 1px lighten(@nav-blue, 45%);
  padding-top: 4px;
  border-radius: 5px;
  padding-bottom: 30px;

  .caption {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    display: block;
    font-size: 1em;
    color: white;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    background-color: lighten(@nav-blue, 0%);
    padding: 8px;
    svg {
      fill: white;
    }
  }

  .baseline .chart-line {
    stroke: transparent;
  }

  .tcp-chart {
    pointer-events: none;
  }

  &:hover {
    cursor: zoom-in;
    border-color: lighten(@nav-blue, 0%);
    background-color: lighten(@nav-blue, 50%);

    .caption {
      display: block;
    }
  }

  &[disabled]:hover {
    cursor: default;
    border-color: lighten(@nav-blue, 45%);
    background: none;
  }
}

.details-info {
  margin-top: 0.5em;
  margin-left: 0.5em;
  margin-right: 0.5em;
  padding-top: 0em;
  line-height: normal;
  font-size: 13pt;
}
