.info-component {
  fill: #999;
  cursor: pointer;

  &:hover {
    fill: #ddd;
  }

}

.tcp .tooltip-inner {
  background-color: white;
  color: black;
  border: 1px solid black;
}

.tcp.tooltip.in {
  filter: alpha(opacity=100);
  opacity: 1;
}

.tcp.tooltip.bottom .tooltip-arrow,
.tcp.tooltip.top .tooltip-arrow {
  border-top-color: white;
}

.progress {
  background-color: #ebeef1;
  background-image: none;
  box-shadow: none;
}